<template>
  <div>
    <b-row class="mt-4 mb-4">
      <b-col md="4" v-for="(team, index) in data" :key="index" class="mb-4">
        <TeamCard
          :teamDetails="team"
          :themeColor="themeColor"
          @click="goToTeamDetails(team.id)"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import TeamCard from "@/components/Teams/Cards/TeamCard.vue";
export default {
  props: ["data", "themeColor", "topicId"],
  components: {
    TeamCard
  },
  methods: {
    goToTeamDetails(teamId) {
      this.$router.push({
        name: "TeamDetail",
        params: {
          topicId: this.topicId,
          teamId: teamId
        }
      });
    }
  }
};
</script>
